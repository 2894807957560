import { AppBar, useScrollTrigger } from '@mui/material';
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Toolbar } from '@mui/material';
import { Link } from 'wouter';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import { useAuth } from 'oidc-react';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch } from '../../../app/hooks';
import { toggle } from '../../../features/menu/menuSlice';
import { DevBanner, ProfileMenu, Menu as PWMenu, PWMenuItem } from '@perry-weather/component-library';
import { PWColors } from '../../../Theme/PWColors';
import { useNotificationSettingsQuery } from '../../../Services/API';
import ForwardArrow from '../../../Assets/NotificationIcons/ForwardArrow';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UserAlerts from './UserAlerts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    title: {
      flexGrow: 1,
      justifyItems: 'space-around',
      justifyContent: 'space-around',
    },
    titleBar: {
      color: theme.palette.text.primary,
      justifyItems: 'space-around',
      justifyContent: 'space-around',
    },
  })
);

export default function MainMenu() {
  const theme = useTheme();
  const classes = useStyles();
  const [notiAnchorEl, setNotiAnchorEl] = React.useState(null);
  const notiOpen = Boolean(notiAnchorEl);
  const dispatch = useAppDispatch();
  /// Annoyingly calling signoutRedirect from oidc-react does not work.
  /// So we have to call the signoutRedirect directly from usermanager,
  /// This means it doesn't trigger onSignout() from oidc-react
  const { userData, userManager } = useAuth();
  const { data: notiSettings } = useNotificationSettingsQuery();

  const handleNotiMenu = (event: any) => {
    setNotiAnchorEl(event.currentTarget);
  };

  const handleNotiClose = () => {
    setNotiAnchorEl(null);
  };

  let trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  const environment = process.env.REACT_APP_ENVIRONMENT ?? 'Local';
  var initials =
    (userData?.profile.given_name || '').substring(0, 1) + (userData?.profile.family_name || '').substring(0, 1);

  const NotificationStateIcon = () => {
    if (notiSettings?.declinesNotifications)
      return <NotificationsOffOutlinedIcon htmlColor={theme.palette.error.main} />;
    else return <NotificationsNoneIcon />;
  };

  return (
    <>
      <AppBar
        position='fixed'
        elevation={trigger ? 24 : 0}
        enableColorOnDark={true}
        style={{
          backgroundColor: trigger ? theme.palette.background.default : 'transparent',
          boxShadow: trigger ? '5px 0px 27px -5px rgba(0, 0, 0, 0.3) !important' : undefined,
        }}>
        <Toolbar style={{ backgroundColor: trigger ? theme.palette.background.default : 'transparent' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={() => dispatch(toggle())}
            className={classes.menuButton}
            size='large'>
            <MenuIcon />
          </IconButton>

          <div className={classes.title} />
          <div className={classes.titleBar}>
            <DevBanner environment={environment} />
            <IconButton
              href='https://support.perryweather.com/hc'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='false'
              color='inherit'
              size='large'>
              <HelpOutlineIcon />
            </IconButton>
            <IconButton
              onClick={handleNotiMenu}
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='false'
              color='inherit'
              size='large'>
              {NotificationStateIcon()}
            </IconButton>
            {notiSettings?.declinesNotifications && (
              <Link to='/NotificationSettings' style={{ borderRadius: 100 }}>
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='false'
                  color='inherit'
                  size='large'>
                  <Typography variant='subtitle1' textAlign='left' color={theme.palette.error.main}>
                    Notifications Disabled
                  </Typography>
                </IconButton>
              </Link>
            )}
            <PWMenu open={notiOpen} anchorEl={notiAnchorEl} onClose={handleNotiClose}>
              <Typography margin='16px' variant='subtitle1' textAlign='left'>
                Notifications
              </Typography>
              <UserAlerts />
              <PWMenuItem>
                <Box display='flex' justifyContent='center' alignItems='center' width='100%' gap={1}>
                  <Link
                    to='/Notifications'
                    style={{
                      textDecoration: 'none',
                      color: PWColors.dark.primary['500'],
                      textAlign: 'center',
                      alignSelf: 'center',
                    }}
                    onClick={handleNotiClose}>
                    View All
                  </Link>
                  <ForwardArrow color={theme.palette.primary.main} />
                </Box>
              </PWMenuItem>
            </PWMenu>
            <ProfileMenu
              initials={initials}
              name={userData?.profile.name ?? ''}
              onLogout={() => userManager.signoutRedirect()}>
              {({ onClose }) => (
                <>
                  <Link
                    to='/Profile'
                    onClick={onClose}
                    style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                    <PWMenuItem>My Profile</PWMenuItem>
                  </Link>
                  <Link
                    to='/NotificationSettings'
                    onClick={onClose}
                    style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                    <PWMenuItem>Notification Settings</PWMenuItem>
                  </Link>
                </>
              )}
            </ProfileMenu>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
