import { Box, Grid, Typography } from '@mui/material';
import Link from '../../Base/Link';
import { Skeleton } from '@mui/material';
import { Card } from '@perry-weather/component-library';
import { useGetAirQualityForLocationQuery } from '../../../Services/API';
import { useAppSelector } from '../../../app/hooks';
import { getSelectedLocation } from '../../../features/dash/dashSlice';
import { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AqiType } from '../../../Constants/AqiConstants';
import AirQualityDetailsModal from './AirQualityDetails';
import AirQualityGauge from './AqiGauge';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuth } from 'oidc-react';

export function AirQualityCard() {
  const selectedLocation = useAppSelector(getSelectedLocation);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const {
    data: airQualityData,
    isFetching: isAirQualityFetching,
    isLoading: isAirQualityLoading,
  } = useGetAirQualityForLocationQuery(selectedLocation?.id ?? skipToken);

  const [isSevere, setIsSevere] = useState(false); //Will need to hook this up once we have policies in place

  const pm25Data = airQualityData?.find(x => x.pollutantName === AqiType.PM25);
  const pm10Data = airQualityData?.find(x => x.pollutantName === AqiType.PM10);
  const o3Data = airQualityData?.find(x => x.pollutantName === AqiType.O3);

  const validPollutants = [pm25Data, pm10Data, o3Data].filter(Boolean);

  const { userData: user } = useAuth();
  const hasAQIThirdPartyAccess = user?.profile?.permissions?.includes('aqi.3paccess');

  return (
    <Card
      header='Air Quality'
      scary={isSevere}
      action={
        <Box
          display='flex'
          justifyContent='center'
          sx={{
            display: airQualityData && airQualityData.length > 0 ? 'flex' : 'none',
            textDecoration: 'none',
            alignItems: 'center',
            cursor: 'pointer',
          }}>
          <Link onClick={() => setDetailsModalOpen(true)}>
            <Typography>View Details</Typography>
          </Link>
        </Box>
      }>
      <ErrorBoundary fallback={<Typography>There was an error while trying to load the Air Quality Data</Typography>}>
      {detailsModalOpen && (
        <AirQualityDetailsModal
          open={detailsModalOpen}
          handleClose={() => setDetailsModalOpen(false)}
          locationId={selectedLocation?.id as string}
        />
      )}
      {isAirQualityFetching || isAirQualityLoading ? (
        <Box display='flex' justifyContent='space-evenly'>
          <Skeleton animation={false} variant='rectangular' width='100%' height={110} />
          <Skeleton animation={false} variant='rectangular' width='100%' height={110} />

          {hasAQIThirdPartyAccess && <Skeleton animation={false} variant='rectangular' width='100%' height={110} />}
        </Box>
      ) : airQualityData && airQualityData.length > 0 ? (
        <Box display='flex' justifyContent='space-evenly' alignItems={'center'} flexGrow={1}>
          <Grid
            item
            sx={{
              flexBasis: validPollutants.length > 1 ? '30%' : '100%',
            }}>
            <Typography variant='h6'>PM 2.5</Typography>
            <AirQualityGauge pollutantData={pm25Data} />
          </Grid>

          <Grid
            item
            sx={{
              flexBasis: validPollutants.length > 1 ? '30%' : '100%',
            }}>
            <Typography variant='h6'>PM 10</Typography>
            <AirQualityGauge pollutantData={pm10Data} />
          </Grid>

          {hasAQIThirdPartyAccess && (
            <Grid
              item
              sx={{
                flexBasis: validPollutants.length > 1 ? '30%' : '100%',
              }}>
              <Typography variant='h6'>Ozone</Typography>
              <AirQualityGauge pollutantData={o3Data} />
            </Grid>
          )}
        </Box>
      ) : (
        <Typography>No data is available for this location</Typography>
      )}
      </ErrorBoundary>
    </Card>
  );
}
export default AirQualityCard;
