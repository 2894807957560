import { DateTimePicker } from '@mui/lab';
import { Grid, Typography, useTheme, TextField as MuiField } from '@mui/material';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { BuilderError, WidgetParams } from '../../Pages/WidgetBuilder';
import { CustomerEntitlements, OrganizationLocation, Policy, useCustomerQuery, usePoliciesForCustomerQuery, useUserSitesQuery } from '../../Services/API';
import { Label } from '../Base';
import { Card, Checkbox, Select, SelectOption, TextArea, TextField } from '@perry-weather/component-library';
import { skipToken } from '@reduxjs/toolkit/dist/query';

interface WidgetBuilderOptionsProps {
  handleWidgetParamUpdate: (
    param: string,
    value: string | string[] | number[] | number | Policy[] | Date | undefined
  ) => void;
  widgetParams: WidgetParams;
  orgLocations: OrganizationLocation[] | undefined;
  builderError: BuilderError;
}

export const WidgetBuilderOptions = (props: WidgetBuilderOptionsProps) => {
  const { handleWidgetParamUpdate, widgetParams, orgLocations, builderError } = props;
  const { data: customerPolicies } = usePoliciesForCustomerQuery(widgetParams.customerId || 0);
  const { data: sites } = useUserSitesQuery(widgetParams.customerId);
  const theme = useTheme();
  const [messageWillNotExpire, setMessagewillNotExpire] = useState<boolean>(
    widgetParams.headerMessageExpiration == undefined
  );
  const [messageExpirationDate, setMessageExpirationDate] = useState<Moment | null>(
    widgetParams.headerMessageExpiration ? moment(widgetParams.headerMessageExpiration) : null
  );

  const orgLocationSelectOptions = orgLocations?.map(location => {
    return { value: location.id, text: location.name } as SelectOption;
  });

  const { data: customer } = useCustomerQuery(widgetParams?.customerId || skipToken);

  const conditionOptions = [
    { value: 0, text: 'Temperature' },
    { value: 1, text: 'WBGT' },
    { value: 2, text: 'Wind Speed'},
    { value: 3, text: 'Precipitation' },
    { value: 4, text: 'Wind Gust' },
    { value: 5, text: 'Feels Like' },
    { value: 6, text: 'PM2.5', header: 'AQI' },
    { value: 7, text: 'PM10', header: 'AQI' },
    { value: 8, text: 'Ozone', header: 'AQI', hide: !customer?.entitlements?.includes(CustomerEntitlements.AQI_3P_ACCESS) },
  ]
  .filter(option => !option.hide);

  // Get unique headers
  const headers = Array.from(new Set(conditionOptions.filter(option => option.header).map(option => option.header)));

  const optionsWithForecasts = [0,1,2,3,4,5];
  const forecastConditionOptions = [ { value: -1, text: 'None' }].concat(conditionOptions.filter(option => optionsWithForecasts.includes(option.value)));

  const groups = sites?.map(site => {
    return { value: site.id, text: site.name } as SelectOption;
  });

  let set = new Set();
  const addToSet = (value: any) => {
    if (!set.has(value)) {
      set.add(value);
      return true;
    }
    return false;
  };
  const sitePolicies = customerPolicies?.filter(
    (policy, index, arr) =>
      policy.sites.includes(widgetParams.policyGroupId || -1) && addToSet(policy.policyTypeShortName)
  );

  if (groups && groups.length > 0) {
    groups.unshift({ value: -1, text: 'None' } as SelectOption);
  }

  const handleMessageExpirationChange = (value: boolean) => {
    if (value) {
      handleWidgetParamUpdate('headerMessageExpiration', undefined);
      setMessageExpirationDate(null);
    }
    setMessagewillNotExpire(value);
  };

  const handleSecondaryConditionChange = (checked: boolean, value: number) => {
    if (checked && !widgetParams.widgetConditions.includes(value)) {
      handleWidgetParamUpdate('widgetConditions', [...widgetParams.widgetConditions, value]);
    } else if (!checked && widgetParams.widgetConditions.includes(value)) {
      handleWidgetParamUpdate(
        'widgetConditions',
        widgetParams.widgetConditions.filter(condition => condition !== value)
      );
    }
  };

  useEffect(() => {
    setMessagewillNotExpire(widgetParams.headerMessageExpiration == undefined);
    setMessageExpirationDate(
      widgetParams.headerMessageExpiration ? moment(widgetParams.headerMessageExpiration) : null
    );
  }, [widgetParams.headerMessageExpiration]);

  useEffect(() => {
    if (widgetParams.policyGroupId) {
      if (sitePolicies) handleWidgetParamUpdate('policies', sitePolicies);
    }
  }, [widgetParams.policyGroupId]);

  //on initial component render, we want to get policies if widget already has a site
  useEffect(() => {
    if (widgetParams.policyGroupId) {
      handleWidgetParamUpdate('policies', sitePolicies);
    }
  }, []);

  const groupOptions = groups;

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item container flexDirection={'row'} flexWrap='nowrap' justifyContent={'space-between'}>
          <Grid item>
            <Typography textAlign={'left'} color={theme.palette.info.light} variant='subtitle1'>
              Location
            </Typography>
          </Grid>
        </Grid>

        <Grid item container flexDirection={'row'} flexWrap='wrap' spacing={2} justifyContent={'space-between'}>
          <Grid xs={12} item>
            <Typography textAlign={'left'} variant='subtitle1'>
              Choose a location
            </Typography>
            <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
              Your widget will pull weather conditions for an organization location.
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Select
              options={orgLocationSelectOptions ?? []}
              placeholder={'Select a location'}
              onChange={value => handleWidgetParamUpdate('orgLocId', value)}
              value={widgetParams.orgLocId}
            />
          </Grid>
        </Grid>
        <Grid item container flexDirection={'row'} flexWrap='wrap' spacing={2} justifyContent={'space-between'}>
          <Grid xs={12} item>
            <Typography textAlign={'left'} variant='subtitle1'>
              Widget Name
            </Typography>
            <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
              Enter a nickname for this widget.
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <TextField
              placeholder={'Name'}
              onChange={e => handleWidgetParamUpdate('name', e.target.value)}
              value={widgetParams.name}
              isError={builderError.name}
            />
          </Grid>
        </Grid>
        {widgetParams.orgLocId && (
          <>
            <hr style={{ margin: '12px 0', width: '100%' }} />

            <Grid item container flexDirection={'row'} flexWrap='nowrap' justifyContent={'space-between'}>
              <Grid item>
                <Typography textAlign={'left'} color={theme.palette.info.light} variant='subtitle1'>
                  Conditions and Forecasts
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  These settings allow you to choose which forecasts and conditions your widget will show.{' '}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              flexDirection={'row'}
              flexWrap='nowrap'
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Grid item xs={6}>
                <Typography textAlign={'left'} variant='subtitle1'>
                  Primary Condition
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  This condition will display in the main container.{' '}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  options={conditionOptions}
                  placeholder={'Select condition'}
                  onChange={value => handleWidgetParamUpdate('primaryCondition', value)}
                  value={widgetParams.primaryCondition}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              flexDirection={'row'}
              flexWrap='nowrap'
              justifyContent={'space-between'}
              alignItems={'flex-start'}>
              <Grid item xs={6}>
                <Typography textAlign={'left'} variant='subtitle1'>
                  Secondary Conditions
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  These optional conditions display below the main container.{' '}
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.info.main}>
                  You can select up to 4 conditions to display.
                </Typography>
              </Grid>
              <Grid item xs={5}>
                {/* First, show all secondary conditions with no headers */}
                {conditionOptions
                  .filter(co => !co.header)
                  .map(option => {
                    const checked = widgetParams.widgetConditions?.includes(option.value);

                    return (
                      <Grid key={option.value} item container>
                        <Checkbox
                          checked={checked}
                          onChange={e => handleSecondaryConditionChange(e.target.checked, option.value)}
                          disabled={!checked && widgetParams.widgetConditions?.length >= 4}
                        />
                        <Label text={option.text}></Label>
                      </Grid>
                    );
                  })}

                {/* Then, show secondary conditions with headers */}
                {headers.map(header => {
                  return (
                    <>
                      <br />
                      <Typography textAlign={'left'} fontWeight={'bold'} fontSize={20}>
                        {header}
                      </Typography>
                      <br />

                      {conditionOptions
                        .filter(option => option.header === header)
                        .map(option => {
                          const checked = widgetParams.widgetConditions?.includes(option.value);

                          return (
                            <Grid key={option.value} item container>
                              <Checkbox
                                checked={checked}
                                onChange={e => handleSecondaryConditionChange(e.target.checked, option.value)}
                                disabled={!checked && widgetParams.widgetConditions?.length >= 4}
                              />
                              <Label text={option.text}></Label>
                            </Grid>
                          );
                        })}
                    </>
                  );
                })}
              </Grid>
            </Grid>

            <Grid
              item
              container
              flexDirection={'row'}
              flexWrap='nowrap'
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Grid item xs={6}>
                <Typography textAlign={'left'} variant='subtitle1'>
                  Hourly Forecast
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  Hourly forecast is displayed under the main container.{' '}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  options={forecastConditionOptions}
                  placeholder={'Select condition'}
                  onChange={value => handleWidgetParamUpdate('hourlyWidgetForecast', value)}
                  value={widgetParams.hourlyWidgetForecast ?? -1}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              flexDirection={'row'}
              flexWrap='nowrap'
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Grid item xs={6}>
                <Typography textAlign={'left'} variant='subtitle1'>
                  Daily Forecast
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  Daily forecast is displayed under the main container.{' '}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  options={forecastConditionOptions}
                  placeholder={'Select condition'}
                  onChange={value => handleWidgetParamUpdate('dailyWidgetForecast', value)}
                  value={widgetParams.dailyWidgetForecast ?? -1}
                />
              </Grid>
            </Grid>

            <hr style={{ margin: '12px 0', width: '100%' }} />

            {groupOptions && groupOptions.length > 0 && (
              <>
                <Grid item container flexDirection={'row'} flexWrap='nowrap' justifyContent={'space-between'}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography textAlign={'left'} color={theme.palette.info.light} variant='subtitle1'>
                        Red Text for Policy Warnings
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                        See when conditions are above your organization’s policy thresholds with red warning text.
                        Choose a User Group below to apply its policies to this widget.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography textAlign={'left'} variant='body2' color={theme.palette.info.main}>
                        You must select a group with a Lightning Zone 1 policy.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  flexDirection={'row'}
                  flexWrap='wrap'
                  spacing={2}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <Grid item xs={12}>
                    <Select
                      options={groupOptions}
                      placeholder={'Select site'}
                      onChange={value => {
                        handleWidgetParamUpdate('policyGroupId', parseInt(value));
                      }}
                      isError={builderError.lightningRadius}
                      value={widgetParams.policyGroupId}
                    />
                  </Grid>
                </Grid>
                {sitePolicies && sitePolicies.length > 0 && (
                  <>
                    <Grid
                      item
                      container
                      flexDirection={'row'}
                      flexWrap='nowrap'
                      justifyContent={'space-between'}
                      alignItems={'center'}>
                      <Grid item xs={6}>
                        <Typography textAlign={'left'} variant='subtitle1'>
                          Policy
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography textAlign={'center'} variant='subtitle1'>
                          Threshold
                        </Typography>
                      </Grid>
                    </Grid>
                    {sitePolicies.map(policy => {
                      if (policy.policyTypeShortName === 'LR2' || policy.policyTypeShortName === 'LR3') {
                        return;
                      }
                      return (
                        <Grid
                          key={policy.id}
                          item
                          container
                          flexDirection={'row'}
                          flexWrap='nowrap'
                          justifyContent={'space-between'}
                          alignItems={'center'}>
                          <Grid item xs={6}>
                            <Typography textAlign={'left'} variant='body2'>
                              {policy.policyTypeName}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography textAlign={'center'} variant='body2'>
                              {policy.threshold}
                              {policy.unit}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                )}
                {widgetParams.policyGroupId && widgetParams.policyGroupId !== -1 && sitePolicies?.length === 0 && (
                  <Grid
                    item
                    container
                    flexDirection={'row'}
                    flexWrap='nowrap'
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <Grid item xs={12}>
                      <Typography textAlign={'center'} color={theme.palette.text.secondary} variant='subtitle1'>
                        This group does not have policies.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            <hr style={{ margin: '12px 0', width: '100%' }} />

            <Grid item container flexDirection={'row'} flexWrap='nowrap' justifyContent={'space-between'}>
              <Grid item>
                <Typography textAlign={'left'} color={theme.palette.info.light} variant='subtitle1'>
                  Custom Message
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  Rained out? Fields closed? Have another message to share? Display it at the top of your widget.
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              flexDirection={'row'}
              flexWrap='wrap'
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Grid item xs={12}>
                <TextArea
                  placeholder='Type your message here.'
                  value={widgetParams.headerMessage}
                  onChange={e => handleWidgetParamUpdate('headerMessage', e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              flexDirection={'row'}
              flexWrap='nowrap'
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Grid item xs={6}>
                <Typography textAlign={'left'} variant='subtitle1'>
                  Expiration Date
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  The message will disappear from the widget on the selected date and time; otherwise, it will remain
                  until you remove it.
                </Typography>
              </Grid>

              <Grid item container xs={12}>
                <Grid item container xs={12} alignItems={'center'} justifyContent={'flex-end'}>
                  <Typography textAlign={'left'} variant='body2'>
                    No Expiration?
                  </Typography>
                  <Checkbox
                    checked={messageWillNotExpire}
                    onChange={e => handleMessageExpirationChange(e.target.checked)}
                  />
                </Grid>

                {!messageWillNotExpire && (
                  <Grid item container xs={12} alignItems={'center'} justifyContent={'flex-end'}>
                    <>
                      <DateTimePicker
                        renderInput={props => <MuiField {...props} />}
                        label='Expiration Date'
                        value={messageExpirationDate}
                        onChange={newValue => {
                          if (newValue) {
                            setMessageExpirationDate(newValue);
                            handleWidgetParamUpdate('headerMessageExpiration', newValue.toDate());
                          }
                        }}
                      />
                    </>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <hr style={{ margin: '12px 0', width: '100%' }} />

            <Grid item container flexDirection={'row'} flexWrap='nowrap' justifyContent={'space-between'}>
              <Grid item>
                <Typography textAlign={'left'} color={theme.palette.info.light} variant='subtitle1'>
                  Appearance
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  These settings allow you to change the appearance of your widget.{' '}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              flexDirection={'row'}
              flexWrap='nowrap'
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Grid item xs={6}>
                <Typography textAlign={'left'} variant='subtitle1'>
                  Theme
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  Choose light or dark mode.
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  options={[
                    { value: 0, text: 'Dark' },
                    { value: 1, text: 'Light' },
                  ]}
                  placeholder={'Select theme'}
                  onChange={value => handleWidgetParamUpdate('theme', value)}
                  value={widgetParams.theme ?? 0}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              flexDirection={'row'}
              flexWrap='nowrap'
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Grid item xs={6}>
                <Typography textAlign={'left'} variant='subtitle1'>
                  Display
                </Typography>
                <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
                  Choose the layout of the main container.
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  options={[
                    { value: 0, text: 'Default' },
                    { value: 1, text: 'Image' },
                    { value: 2, text: 'Radar' },
                  ]}
                  placeholder={'Select theme'}
                  onChange={value => handleWidgetParamUpdate('type', value)}
                  value={widgetParams.type ?? 1}
                />
              </Grid>
            </Grid>

            {/* 
                        TODO: Add a modal? for "real" preview with selectable width
                    <Grid item container flexDirection={'row'} flexWrap='nowrap' justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item xs={6}>
                            <Typography textAlign={'left'} variant='subtitle1'>Width</Typography>
                            <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>The Perry Weather Widget is responsive! Preview your widget at different widths.</Typography>
                        </Grid>
                        <Grid item xs={5}>
                        <TextField 
                            placeholder="Width" 
                            value={widgetParams?.width?.toString() || "500"} 
                            onChange={(e) => handleWidgetParamUpdate('width', parseInt(e.target.value))}
                            endAdornment={<InputAdornment position="end">px</InputAdornment>}
                            
                        />
                        </Grid>
                    
                    </Grid> */}
          </>
        )}
      </Grid>
    </Card>
  );
};
