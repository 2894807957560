import { GridColDef } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { DateFormatter, DateTimeFormatter } from '../../features/Time/TimeHelpers';
import { AirQualityVM } from '../../Services/API';
import Table from '../Base/BaseTable';

interface AirQualityTableProps {
  data: AirQualityVM[];
}

interface IAirQualityRow {
  id: string;
  name: string;
  customerName: string;
  weatherStationName: string;
  lastActivity?: string;
  Now?: number;
  '1-Hour'?: number;
  '1-Day'?: number;
  '1-Week'?: number;
}

class AirQualityRow implements IAirQualityRow {
  id: string;
  name: string;
  customerName: string;
  weatherStationName: string;
  lastActivity?: string;
  now?: number;
  '1-Hour'?: number;
  '1-Day'?: number;
  '1-Week'?: number;

  constructor(airQualityVm: AirQualityVM) {
    this.id = airQualityVm.id;
    this.name = airQualityVm.name;
    this.customerName = airQualityVm.customerName;
    this.weatherStationName = airQualityVm.weatherStationName;
    this.lastActivity = airQualityVm.lastActivity
      ? `${DateFormatter(airQualityVm.lastActivity.toString())}, ${DateTimeFormatter(
          airQualityVm.lastActivity.toString()
        )}`
      : undefined;
    this.now = airQualityVm.pM2_5?.nowcast;
    this['1-Hour'] = airQualityVm.pM2_5['1-Hour'];
    this['1-Day'] = airQualityVm.pM2_5['1-Day'];
    this['1-Week'] = airQualityVm.pM2_5['1-Week'];
  }
}

export function AirQualityTable(props: AirQualityTableProps) {
  const { data } = props;
  const [rows, setRows] = useState<AirQualityRow[]>([]);

  useMemo(() => {
    if (data) setRows(data.map(vm => new AirQualityRow(vm)));
  }, [data]);

  const cols: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 0.2 },
    { field: 'weatherStationName', headerName: 'Weather Station', flex: 0.3 },
    { field: 'now', headerName: 'NowCast', flex: 0.1 },
    { field: '1-Hour', flex: 0.1 },
    { field: '1-Day', flex: 0.1 },
    { field: '1-Week', flex: 0.1 },
    { field: 'lastActivity', headerName: 'Last Activity', flex: 0.3 },
  ];

  return <Table rowHeight={54} rowSpace={8} rowsPerPageOptions={[10]} rows={rows} cols={cols} />;
}
