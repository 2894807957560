import { Typography } from "@mui/material";
import { Page } from "@perry-weather/component-library";
import { PWColors } from "../Theme/PWColors";

function ErrorBackground() {
    return (
        <div className='Background-Dark'>
        <div className='Background-Dark-Group'>
          <div className='Background-Dark-Gradient-1'></div>
          <div className='Background-Dark-Gradient-2'></div>
          <div className='Background-Dark-Circles-Group'>
            <div className='Background-Dark-Circles-2'></div>
            <div className='Background-Dark-Circles-1'></div>
          </div>
        </div>
        <div className='Background-Dark-Gradient'></div>
      </div>
    );
}
export default function ErrorPage() {
    return (
        <>
        <div className="App">
            <Page title={<> </>}>
                <Typography variant="h1" color={PWColors.dark.primary[100]}>
                    A fatal error occurred.
                </Typography>
                <Typography variant="h2" color={PWColors.dark.primary[100]}>
                    Contact support@perryweather.com if this issue persists.
                </Typography>
            </Page>
        </div>
        <ErrorBackground />
        </>
    );
}
