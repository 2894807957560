import { Grid, Typography, useTheme } from '@mui/material';
import { PollutantData } from '../../../Services/API';
import ProgressCircle from '../../Animated/ProgressCircle';

type AirQualityGaugeProps = {
  pollutantData?: PollutantData;
  width?: number;
};

const AQI_RANGE_MAX = 500;
const AQI_RANGE_MIN = 0;

export default function AirQualityGauge({ pollutantData, width = 100 }: AirQualityGaugeProps) {
  const theme = useTheme();

  const getAQI = (pollutant?: PollutantData) => {

    if (pollutant?.pollutantName !== 'Ozone'){
        return pollutant?.aqiData?.nowCastIndex ?? pollutant?.aqiData?.oneHourIndex;
    } 

    if (pollutant?.aqiData.nowCastIndex) return pollutant?.aqiData.nowCastIndex;

    if (pollutant?.aqiData.oneHourIndex?.value && pollutant?.aqiData.eightHourIndex?.value) {
      if (pollutant?.aqiData.oneHourIndex.value >= pollutant?.aqiData.eightHourIndex.value) {
        return pollutant?.aqiData.oneHourIndex;
      } else {
        return pollutant?.aqiData.eightHourIndex;
      }
    } else if (pollutant?.aqiData.oneHourIndex?.value) {
      return pollutant?.aqiData.oneHourIndex;
    } else {
      return pollutant?.aqiData.eightHourIndex;
    }
  };

  const aqiIndex = getAQI(pollutantData);

  return (
    <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <ProgressCircle
        width={width}
        value={aqiIndex?.value}
        max={AQI_RANGE_MAX}
        min={AQI_RANGE_MIN}
        color={aqiIndex?.color}
      />
      <Typography marginTop={-3} variant='h5'>
        {aqiIndex?.value ?? '--'}
      </Typography>
      <Typography fontSize={16} textAlign='center' color={theme.palette.text.secondary}>
        {aqiIndex?.shortDescription ?? 'N/A'}
      </Typography>
    </Grid>
  );
}
