import Container from '@mui/material/Container';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ConsultationIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ExtensionOutlined from '@mui/icons-material/ExtensionOutlined';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import TeamIcon from '@mui/icons-material/GroupOutlined';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link, useLocation } from 'wouter';
import { selectMenu, toggle } from '../../features/menu/menuSlice';
import { useAppSelector } from '../../app/hooks';
import { PWLogo } from '../../Assets/PWLogo';
import { FactCheck as PolicyIcon, Send, VideoCameraBackOutlined } from '@mui/icons-material';
import { Theme, Typography, useMediaQuery, useTheme, Tooltip as MuiTooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';

import './Sidebar.css';
import { useDispatch } from 'react-redux';
import { CellularNetworkIcon } from '../../Assets/CellularNetwork';
import { Sidebar as PWSidebar, SidebarListItem, MobileSidebarListItem } from '@perry-weather/component-library';
import { AirQuality } from '../../Assets';
import PermissionGate from './PermissionGate';
import { CustomerEntitlements, UserRoles } from '../../Constants/Constants';

const useTooltipStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.background.disabled,
  },
  tooltip: {
    backgroundColor: theme.palette.background.disabled,
  },
}));

export default function Sidebar() {
  const theme = useTheme();

  const hidden = useMediaQuery(theme.breakpoints.up('sm'));

  const mobileOpen = useAppSelector(selectMenu);
  const dispatch = useDispatch();
  const [location] = useLocation();

  const { SuperAdmin, Admin, AssistantAdmin } = UserRoles;
  const url = location.toLocaleLowerCase();
  const tooltipStyles = useTooltipStyles();

  const SidebarTooltip = (props: { title: string; children: React.ReactElement }) => {
    return (
      <MuiTooltip
        classes={tooltipStyles}
        title={<Typography>{props.title}</Typography>}
        arrow
        placement='right'
        enterDelay={300}
        leaveDelay={200}>
        {props.children}
      </MuiTooltip>
    );
  };

  const desktopDrawer = () => (
    <PWSidebar mobileOpen={false}>
      <List>
        <SidebarListItem>
          <Link to='/'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <PWLogo color={theme.palette.background.default} />
            </div>
          </Link>
        </SidebarListItem>
        <SidebarListItem selected={url === '/'}>
          <Link to='/'>
            <ListItemIcon>
              <SidebarTooltip title='Home'>
                <div className='svg-container'>
                  <HomeIcon />
                </div>
              </SidebarTooltip>
            </ListItemIcon>
          </Link>
        </SidebarListItem>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <SidebarListItem selected={url === '/hardware'}>
            <Link to='/Hardware'>
              <ListItemIcon>
                <SidebarTooltip title='On-Site Hardware'>
                  <div className='svg-container'>
                    <CellularNetworkIcon width={24} height={24} color={theme.palette.action.inactive} />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.AirQualityIndex]}>
          <SidebarListItem selected={url === '/airquality'}>
            <Link to='/AirQuality' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <SidebarTooltip title='Air Quality Index'>
                  <div className='svg-container'>
                    <AirQuality height={24} width={24} color={theme.palette.action.inactive} />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MetSupport]}>
          <SidebarListItem selected={url === '/consultation'}>
            <Link to='/Consultation'>
              <ListItemIcon>
                <SidebarTooltip title='Ask a Meteorologist'>
                  <div className='svg-container'>
                    <ConsultationIcon />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <SidebarListItem selected={url === '/users'}>
            <Link to='/Users'>
              <ListItemIcon>
                <SidebarTooltip title='Users'>
                  <div className='svg-container'>
                    <TeamIcon />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <SidebarListItem selected={url === '/policies'}>
            <Link to='/Policies'>
              <ListItemIcon>
                <SidebarTooltip title='Policies'>
                  <div className='svg-container'>
                    <PolicyIcon />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MassNotify]}>
          <SidebarListItem selected={url === '/massnotify'}>
            <Link to='/MassNotify'>
              <ListItemIcon>
                <SidebarTooltip title='Notify Users'>
                  <div className='svg-container'>
                    <Send />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.WidgetAccess]}>
          <SidebarListItem selected={url === '/widgets'}>
            <Link to='/Widgets'>
              <ListItemIcon>
                <SidebarTooltip title='Widgets'>
                  <div className='svg-container'>
                    <WidgetsOutlinedIcon />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.LiveCamera]}>
          <SidebarListItem selected={url === '/livecamera'}>
            <Link to='/LiveCamera'>
              <ListItemIcon>
                <SidebarTooltip title='Live Camera'>
                  <div className='svg-container'>
                    <VideoCameraBackOutlined />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.IntegrationsSinglewire]}>
          <SidebarListItem selected={url === '/integrations'}>
            <Link to='/Integrations'>
              <ListItemIcon>
                <SidebarTooltip title='Integrations'>
                  <div className='svg-container'>
                    <ExtensionOutlined />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.BroadcastViewAccess]}>
          <SidebarListItem selected={url === '/broadcast'}>
            <Link to='/Broadcast'>
              <ListItemIcon>
                <SidebarTooltip title='Broadcast'>
                  <div className='svg-container'>
                    <PodcastsIcon />
                  </div>
                </SidebarTooltip>
              </ListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
      </List>
    </PWSidebar>
  );

  const closeDrawer = () => dispatch(toggle());

  const mobileDrawer = () => (
    <PWSidebar mobileOpen={mobileOpen} onClose={closeDrawer}>
      <List>
        <MobileSidebarListItem>
          <Link to='/' onClick={closeDrawer}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <PWLogo color={theme.palette.background.default} />
            </div>
          </Link>
        </MobileSidebarListItem>
        <MobileSidebarListItem selected={url === '/'}>
          <Link to='/' onClick={closeDrawer}>
            <ListItemIcon>
              <div className='svg-container'>
                <HomeIcon fontSize='large' />
                <Typography fontSize='22px'>Home</Typography>
              </div>
            </ListItemIcon>
          </Link>
        </MobileSidebarListItem>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <MobileSidebarListItem selected={url === '/hardware'}>
            <Link to='/Hardware' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <CellularNetworkIcon height={36} width={36} color={theme.palette.action.inactive} />
                  <Typography fontSize='22px'>On-Site Hardware</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.AirQualityIndex]}>
          <MobileSidebarListItem selected={url === '/airquality'}>
            <Link to='/AirQuality' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <AirQuality height={36} width={36} color={theme.palette.action.inactive} />
                  <Typography fontSize='22px'>Air Quality Index</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MetSupport]}>
          <MobileSidebarListItem selected={url === '/consultation'}>
            <Link to='/Consultation' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <ConsultationIcon fontSize='large' />
                  <Typography fontSize='22px'>Ask A Meteorologist</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <MobileSidebarListItem selected={url === '/users'}>
            <Link to='/Users' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <TeamIcon fontSize='large' />
                  <Typography fontSize='22px'>Users</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <MobileSidebarListItem selected={url === '/policies'}>
            <Link to='/Policies' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <PolicyIcon fontSize='large' />
                  <Typography fontSize='22px'>Policies</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MassNotify]}>
          <MobileSidebarListItem selected={url === '/massnotify'}>
            <Link to='/MassNotify' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <Send fontSize='large' />
                  <Typography fontSize='22px'>Notify Users</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.WidgetAccess]}>
          <MobileSidebarListItem selected={url === '/widgets'}>
            <Link to='/Widgets' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <WidgetsOutlinedIcon fontSize='large' />
                  <Typography fontSize='22px'>Widgets</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.LiveCamera]}>
          <MobileSidebarListItem selected={url === '/livecamera'}>
            <Link to='/LiveCamera' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <VideoCameraBackOutlined fontSize='large' />
                  <Typography fontSize='22px'>Live Camera</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]} allowedEntitlements={[]}>
          {' '}
          {/* TODO: remove this when the feature is ready to launch, as integrations are not tied to an entitlement */}
          <MobileSidebarListItem selected={url === '/integrations'}>
            <Link to='/Integrations' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <ExtensionOutlined fontSize='large' />
                  <Typography fontSize='22px'>Integrations</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.BroadcastViewAccess]}>
          {' '}
          {/* TODO: remove this when the feature is ready to launch, as integrations are not tied to an entitlement */}
          <MobileSidebarListItem selected={url === '/broadcast'}>
            <Link to='/Broadcast' onClick={closeDrawer}>
              <ListItemIcon>
                <div className='svg-container'>
                  <PodcastsIcon fontSize='large' />
                  <Typography fontSize='22px'>Broadcast</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
      </List>
    </PWSidebar>
  );

  return <Container>{hidden ? desktopDrawer() : mobileDrawer()}</Container>;
}
