/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import {
  useHistoricalForHourlyQuery,
  useHistoricalForWeekQuery,
  WeatherStationAvg,
  useHistoricalForMonthQuery,
  useHistoricalForCustomQuery,
  DateRange,
  useWxDataQuery,
  useWXImageLastModifiedQuery,
  useStationAirQualityQuery,
  useWxQuery,
} from '../Services/API';
import CurrentConditionsContainer from '../Components/CurrentConditions/CurrentConditionsContainer';
import { ArrowBack } from '@mui/icons-material';

import Link from '../Components/Base/Link';
import { useTheme } from '@mui/styles';
import { WxImage } from '../Components/Base';
import { Card, Tooltip, Page } from '@perry-weather/component-library';
import BaseTimeChart, { TimeChartProps } from '../Components/Base/BaseTimeChart';
import { Serie } from '@nivo/line';

import DateRangePicker from '@mui/lab/DateRangePicker';
import { DateRange as MUIDateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { ExportDataModal } from '../Components/Historical/ExportDataModal';
import { ConvertToUtc, formatTimeStringFromMins, getTimeDifferenceInMins } from '../features/Time/TimeHelpers';
import { useAuth } from 'oidc-react';

import PoweredByPW from '../Assets/powered_by_pw.png';
import ConditionsTimeline from '../Components/Historical/ConditionsTimeline';
import moment from 'moment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EvapotranspirationTimeline from '../Components/Historical/EvapotranspirationTimeline';

enum HistoricalRange {
  'Hourly',
  '7Days',
  '30Days',
  'Custom',
}

enum HisotricalEtRange {
  '3Days',
  '7Days',
  'Custom',
}

const boldedPrecipTextDesc = 'Radar-Correction Precipitation';
const precipTextDesc = ' is enabled on this weather station. Rainfall totals will update hourly.';

function DegreesToWindDir(degrees: number): string {
  const directions = [
    { dir: 'N', deg: 0 },
    { dir: 'NE', deg: 45 },
    { dir: 'E', deg: 90 },
    { dir: 'SE', deg: 135 },
    { dir: 'S', deg: 180 },
    { dir: 'SW', deg: 225 },
    { dir: 'W', deg: 270 },
    { dir: 'NW', deg: 315 },
    { dir: 'N', deg: 360 },
  ];

  while (degrees < 0) {
    degrees += 360;
  }

  // Round to nearest 45 degrees
  const moduloDegrees = degrees % 360;
  const roundedDegrees = Math.round(moduloDegrees / 45) * 45;

  return directions.find(dir => dir.deg === roundedDegrees)?.dir ?? '';
}

export function Historical(props: any) {
  const theme = useTheme();
  let auth = useAuth();

  const { weatherStationId } = props.params;
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [historicalRange, setHistoricalRange] = useState(HistoricalRange['7Days']);
  const [historicalETRange, setHistoricalETRange] = useState(HisotricalEtRange['7Days']);
  const [seriesTemp, setSeriesTemp] = useState<TimeChartProps>();
  const [seriesWind, setSeriesWind] = useState<TimeChartProps>();
  const [seriesPrecip, setSeriesPrecip] = useState<TimeChartProps>();
  const [dateRange, setDateRange] = useState<DateRange>({
    start: (function (d) {
      d.setDate(d.getDate() - 8);
      return d;
    })(new Date()),
    end: (function (d) {
      d.setDate(d.getDate() - 1);
      return d;
    })(new Date()),
  });

  const [etDateRange, setEtDateRange] = useState<DateRange>({
    start: (function (d) {
      d.setDate(d.getDate() - 7);
      return d;
    })(new Date()),
    end: (function (d) {
      d.setDate(d.getDate() - 1);
      return d;
    })(new Date()),
  });
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [exportType, setExportType] = useState<string>('historical_data');

  const { data: wxData, isLoading: isWxDataLoading } = useWxDataQuery(weatherStationId ?? '', {
    skip: weatherStationId === undefined,
    pollingInterval: 60000,
  });

  const { data: imageLastModified } = useWXImageLastModifiedQuery(wxData?.id || '', {
    skip: !wxData?.id,
    pollingInterval: 300000,
  });

  const { data: weatherStationDetails } = useWxQuery(weatherStationId);

  let lastUpdated;

  let lastUpdatedMins = imageLastModified
    ? Math.round(getTimeDifferenceInMins(ConvertToUtc(imageLastModified)))
    : undefined;

  const shouldSkipCustomQuery = () => {
    if (weatherStationId === undefined || dateRange === undefined) {
      return true;
    } else {
      let start = new Date(dateRange.start.toString());
      let end = new Date(dateRange.end.toString());

      let timeDiffInMs: any = end.getTime() - start.getTime();
      let timeDiffInDays = timeDiffInMs / 1000 / 60 / 60 / 24;

      return timeDiffInDays > 90;
    }
  };

  const hasFeature = (featureName: string) =>
    weatherStationDetails?.features.some(feature => feature.displayName.toUpperCase() === featureName.toUpperCase());

  const { data: stationAirQuality } = useStationAirQualityQuery(weatherStationId ? weatherStationId : '', {
    skip: weatherStationId === undefined,
  });
  const { data: historicalHourly } = useHistoricalForHourlyQuery(weatherStationId ? weatherStationId : '', {
    skip: weatherStationId === undefined,
  });
  const { data: historicalWeek } = useHistoricalForWeekQuery(weatherStationId ? weatherStationId : '', {
    skip: weatherStationId === undefined,
  });
  const { data: historicalMonth } = useHistoricalForMonthQuery(weatherStationId ? weatherStationId : '', {
    skip: weatherStationId === undefined,
  });
  const { data: historicalCustom } = useHistoricalForCustomQuery(
    {
      id: weatherStationId !== undefined ? weatherStationId : '',
      startDate: dateRange ? dateRange!.start.toISOString() : '',
      endDate: dateRange ? dateRange!.end.toISOString() : '',
    },
    { skip: shouldSkipCustomQuery() }
  );

  const changeHistoricalRange = (range: HistoricalRange) => {
    setHistoricalRange(range);
  };

  if (wxData) {
    lastUpdated = new Date(wxData?.lastUpdated?.toString() + 'Z');
  }

  useEffect(() => {
    if (historicalWeek && historicalRange === HistoricalRange['7Days']) {
      setUpChartData(historicalWeek);
    } else if (historicalMonth && historicalRange === HistoricalRange['30Days']) {
      setUpChartData(historicalMonth);
    } else if (historicalHourly && historicalRange === HistoricalRange.Hourly) {
      setUpChartData(historicalHourly);
    } else if (historicalCustom && historicalRange === HistoricalRange.Custom) {
      setUpChartData(historicalCustom);
    }
  }, [historicalHourly, historicalWeek, historicalMonth, historicalCustom, historicalRange]);

  const handleDateRangeChange = (dateRange: MUIDateRange<Date>) => {
    let startDate = dateRange[0];
    let endDate = dateRange[1];
    if (dateRange && startDate !== null && endDate !== null) {
      let start = new Date(startDate.toString());
      let end = new Date(endDate.toString());

      let dateNow = new Date();

      if (end > start && dateNow > end) {
        setDateRange({ start: startDate, end: endDate });
      }
    }
  };

  const handleEtDateRangeChange = (dateRange: MUIDateRange<Date>) => {
    let startDate = dateRange[0];
    let endDate = dateRange[1];
    if (dateRange && startDate !== null && endDate !== null) {
      let start = new Date(startDate.toString());
      let end = new Date(endDate.toString());

      let dateNow = new Date();

      if (end > start && dateNow > end) {
        setEtDateRange({ start, end });
      }
    }
  };

  const handleExportOpen = (type: string) => {
    setExportType(type);
    setOpenExportModal(true);
  };

  const setUpChartData = (historicalAverages: WeatherStationAvg[]) => {
    if (historicalRange !== HistoricalRange.Hourly) {
      let serieLow: Serie = { id: 'Low', data: [] };
      let serieAvg: Serie = { id: 'Avg', data: [] };
      let serieHigh: Serie = { id: 'High', data: [] };

      let serieWindSpeed: Serie = { id: 'Wind Speed (mph)', data: [] };
      let serieWindGust: Serie = { id: 'Wind Gust (mph)', data: [] };

      let seriePrecip: Serie = { id: 'Precip (in)', data: [] };

      historicalAverages.forEach(value => {
        let dateStr = '';
        var d = new Date(value.date.toString());
        dateStr =
          d.getFullYear() +
          '-' +
          (d.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          d.getDate().toString().padStart(2, '0');
        var windGust = value.maxWindGust ? Math.round(value.maxWindGust) : 0;

        serieLow.data.push({ x: dateStr, y: Math.round(value.minAmbientTemperature) });
        serieAvg.data.push({ x: dateStr, y: Math.round(value.avgAmbientTemperature) });
        serieHigh.data.push({ x: dateStr, y: Math.round(value.maxAmbientTemperature) });

        serieWindSpeed.data.push({ x: dateStr, y: Math.round(value.maxWindSpeed) });
        serieWindGust.data.push({ x: dateStr, y: windGust });

        seriePrecip.data.push({ x: dateStr, y: Math.round(value.rainTotal * 100) / 100 });
      });

      var lowTemps: number[] = serieLow.data.map(value => {
        return value.y as number;
      });

      var highTemps: number[] = serieHigh.data.map(value => {
        return value.y as number;
      });

      var highWinds: number[] = serieWindSpeed.data.map(value => {
        return value.y as number;
      });

      var highGusts: number[] = serieWindGust.data.map(value => {
        return value.y as number;
      });

      var highPrecips: number[] = seriePrecip.data.map(value => {
        return value.y as number;
      });

      let tempSeries: Serie[] = [];

      tempSeries.push(serieLow);
      tempSeries.push(serieAvg);
      tempSeries.push(serieHigh);

      let minTemp = Math.min.apply(Math, lowTemps) - 30;
      let maxTemp = Math.max.apply(Math, highTemps) + 10;

      setSeriesTemp({
        data: tempSeries,
        max: maxTemp,
        min: minTemp,
        formatType: 'day',
        colors: [theme.palette.success.main, theme.palette.warning.main, theme.palette.error.main],
      });

      let windSeries: Serie[] = [];
      windSeries.push(serieWindSpeed);
      windSeries.push(serieWindGust);

      let maxWind = Math.max.apply(Math, highWinds) + 5;
      let maxGust = Math.max.apply(Math, highGusts) + 5;

      setSeriesWind({
        data: windSeries,
        max: maxWind > maxGust ? maxWind : maxGust,
        min: 0,
        formatType: 'day',
        colors: [theme.palette.primary.main, theme.palette.success.main],
      });

      let precipSeries: Serie[] = [];
      precipSeries.push(seriePrecip);
      let maxPrecip = Math.max.apply(Math, highPrecips) + 0.5;

      setSeriesPrecip({
        data: precipSeries,
        max: maxPrecip,
        min: 0,
        formatType: 'day',
        colors: [theme.palette.primary.main],
      });
    } else {
      let serieTemp: Serie = { id: 'Temp', data: [] };

      let serieWindSpeed: Serie = { id: 'Wind Speed (mph)', data: [] };
      let serieWindGust: Serie = { id: 'Wind Gust (mph)', data: [] };

      var seriePrecip: Serie = { id: 'Precip (in)', data: [] };

      historicalAverages.forEach(value => {
        let dateStr = '';
        var date = new Date(value.date.toString() + 'Z');
        // Using moment to format the date. The method we used before was unstable and broke due to a chrome update.
        dateStr = moment(date).format('YYYY-MM-DD HH:mm:ss');
        var windGust = value.maxWindGust ? Math.round(value.maxWindGust) : 0;

        serieTemp.data.push({ x: dateStr, y: Math.round(value.avgAmbientTemperature) });

        serieWindSpeed.data.push({ x: dateStr, y: Math.round(value.maxWindSpeed) });
        serieWindGust.data.push({ x: dateStr, y: windGust });

        seriePrecip.data.push({ x: dateStr, y: Math.round(value.maxRain1Hr * 100) / 100 });
      });

      let temps: number[] = serieTemp.data.map(value => {
        return value.y as number;
      });

      let highWinds: number[] = serieWindSpeed.data.map(value => {
        return value.y as number;
      });

      let highGusts: number[] = serieWindGust.data.map(value => {
        return value.y as number;
      });

      let highPrecips: number[] = seriePrecip.data.map(value => {
        return value.y as number;
      });

      let tempSeries: Serie[] = [];
      tempSeries.push(serieTemp);

      let minTemp = Math.min.apply(Math, temps) - 30;
      let maxTemp = Math.max.apply(Math, temps) + 10;

      setSeriesTemp({
        data: tempSeries,
        max: maxTemp,
        min: minTemp,
        formatType: 'hour',
        colors: [theme.palette.warning.main, theme.palette.primary.main],
      });

      let windSeries: Serie[] = [];
      windSeries.push(serieWindSpeed);
      windSeries.push(serieWindGust);

      let maxWind = Math.max.apply(Math, highWinds) + 5;
      let maxGust = Math.max.apply(Math, highGusts) + 5;

      setSeriesWind({
        data: windSeries,
        max: maxWind > maxGust ? maxWind : maxGust,
        min: 0,
        formatType: 'hour',
        colors: [theme.palette.primary.main, theme.palette.success.main],
      });

      let precipSeries: Serie[] = [];
      precipSeries.push(seriePrecip);
      let maxPrecip = Math.max.apply(Math, highPrecips) + 0.5;

      setSeriesPrecip({
        data: precipSeries,
        max: maxPrecip,
        min: 0,
        formatType: 'hour',
        colors: [theme.palette.primary.main],
      });
    }
  };

  const shouldShowCustomRangeError = () => {
    if (wxData === undefined || dateRange === undefined) {
      return false;
    } else {
      let start = new Date(dateRange.start.toString());
      let end = new Date(dateRange.end.toString());

      let timeDiffInMs: any = end.getTime() - start.getTime();
      let timeDiffInDays = timeDiffInMs / 1000 / 60 / 60 / 24;

      return timeDiffInDays > 90;
    }
  };

  const shouldShowCustomEtRangeError = () => {
    const MAX_ET_DAYS = 14;

    if (wxData === undefined || etDateRange === undefined) {
      return false;
    } else {
      let start = new Date(etDateRange.start.toString());
      let end = new Date(etDateRange.end.toString());

      let timeDiffInMs: any = end.getTime() - start.getTime();
      let timeDiffInDays = timeDiffInMs / 1000 / 60 / 60 / 24;

      return timeDiffInDays > MAX_ET_DAYS;
    }
  };

  return (
    <Page docTitle='' title={<></>}>
      <Grid container spacing={6}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Grid direction='column' container spacing={3}>
              <Grid item textAlign='start' xs={12}>
                {auth.userData && (
                  <Link onClick={() => window.history.back()}>
                    <ArrowBack sx={{ width: 20, height: 16 }} />
                    <Typography>Back</Typography>
                  </Link>
                )}
                <Typography variant='h3' color='textPrimary'>
                  {wxData ? wxData.locationName : ''}
                </Typography>
                {lastUpdated && (
                  <Typography color='textSecondary' overflow='visible' noWrap>
                    {'Updated ' + formatTimeStringFromMins(Math.round(getTimeDifferenceInMins(lastUpdated)))}
                  </Typography>
                )}
                {wxData && wxData.calibratedData?.rainUpdatedTime !== undefined ? (
                  <Box display='flex'>
                    <Typography color='textSecondary' overflow='visible' noWrap>
                      {'Precipitation updated ' +
                        formatTimeStringFromMins(
                          Math.round(getTimeDifferenceInMins(ConvertToUtc(wxData.calibratedData?.rainUpdatedTime)))
                        )}
                    </Typography>
                    <Box display='flex' justifyContent='center' flexDirection='column'>
                      <Tooltip
                        title={
                          <Box>
                            <Typography component='span'>{boldedPrecipTextDesc}</Typography>
                            <Typography component='span'>{precipTextDesc}</Typography>
                          </Box>
                        }
                        arrow
                        placement='top'
                        enterDelay={300}
                        leaveDelay={200}>
                        <HelpOutlineIcon color='info' sx={{ fontSize: 14 }} />
                      </Tooltip>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={7}>
                {!isWxDataLoading && wxData ? (
                  <CurrentConditionsContainer
                    conditions={{
                      temp: wxData.ambientTemperature,
                      feelsLike:
                        wxData.ambientTemperature && wxData.ambientTemperature >= 80
                          ? wxData.heatIndex
                          : wxData.ambientTemperature &&
                            wxData.ambientTemperature <= 50 &&
                            wxData.windSpeed &&
                            wxData.windSpeed >= 5
                          ? wxData.windChill
                          : wxData.ambientTemperature,
                      precip: wxData.rainToday,
                      wbgt: wxData.wetBulbGlobalTemp,
                      windSpeed: wxData.windSpeed,
                      windDirection: wxData.windDirection ?? 0,
                      windDirectionText: wxData.windDirection != null ? DegreesToWindDir(wxData.windDirection) : '',
                      calibratedConditions: {
                        precip: wxData.calibratedData?.rainToday,
                        precipUpdatedTime: wxData.calibratedData?.rainUpdatedTime,
                      },
                    }}
                    isApprox={false}
                  />
                ) : (
                  <Skeleton height={200} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} display='flex' justifyContent='flex-end' alignSelf='flex-end'>
            {weatherStationId && (
              <WxImage
                weatherStationId={weatherStationId}
                expandable
                showStatus={false}
                style={{ width: '100%', height: 250 }}
              />
            )}
          </Grid>
          {weatherStationId && lastUpdatedMins !== undefined && (
            <Grid item xs={12} display='flex' justifyContent='flex-end'>
              <Typography color='textSecondary'>Image updated {formatTimeStringFromMins(lastUpdatedMins)}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item container>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={12} md={3} textAlign='start' display='flex' alignItems='center'>
              <Typography variant='h5' color='textPrimary'>
                Historical Data
              </Typography>
              {auth.userData && (
                <Grid ml={1}>
                  <Link onClick={() => handleExportOpen('historical_data')}>
                    <Typography>Export Data</Typography>
                  </Link>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={9} display='flex' justifyContent='flex-end'>
              <Link
                inactive={historicalRange !== HistoricalRange.Hourly}
                onClick={() => {
                  changeHistoricalRange(HistoricalRange.Hourly);
                }}>
                <Typography>Hourly</Typography>
              </Link>
              <Divider style={{ height: 16, width: 1, borderColor: theme.palette.divider, margin: 16 }} />
              <Link
                inactive={historicalRange !== HistoricalRange['7Days']}
                onClick={() => {
                  changeHistoricalRange(HistoricalRange['7Days']);
                }}>
                <Typography>7 Days</Typography>
              </Link>
              <Divider style={{ height: 16, width: 1, borderColor: theme.palette.divider, margin: 16 }} />
              <Link
                inactive={historicalRange !== HistoricalRange['30Days']}
                onClick={() => {
                  changeHistoricalRange(HistoricalRange['30Days']);
                }}>
                <Typography>30 Days</Typography>
              </Link>
              <Divider style={{ height: 16, width: 1, borderColor: theme.palette.divider, margin: 16 }} />
              <Link
                inactive={historicalRange !== HistoricalRange.Custom}
                onClick={() => {
                  changeHistoricalRange(HistoricalRange.Custom);
                }}>
                <Typography>Custom</Typography>
              </Link>
            </Grid>
          </Grid>

          {historicalRange === HistoricalRange.Custom && (
            <Grid item container xs={12} display='flex' justifyContent='flex-end' marginTop={2} marginBottom={2}>
              <DateRangePicker
                label='Start Date'
                inputFormat='MM/DD/yyyy'
                value={[new Date(dateRange.start), new Date(dateRange.end)]}
                // maxDate={todayDate}
                onChange={dateRange => handleDateRangeChange(dateRange)}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} sx={{ maxWidth: 150 }} />
                    <Box height='100%' marginLeft={1} marginRight={1}>
                      <Typography height='100%' variant='h3'>
                        -
                      </Typography>
                    </Box>
                    <TextField {...endProps} sx={{ maxWidth: 150 }} />
                  </>
                )}
              />
            </Grid>
          )}

          {historicalRange === HistoricalRange.Custom && shouldShowCustomRangeError() && (
            <Grid item container xs={12} display='flex' justifyContent='flex-end'>
              <Typography color={theme.palette.error.main}>
                Custom Range must be at most 90 days. Try exporting data if longer date range is needed.
              </Typography>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card fullContent>
                {!seriesTemp ? (
                  <Skeleton height={300} />
                ) : (
                  <BaseTimeChart
                    style={{
                      margin: 16,
                      height: 300,
                    }}
                    bottomTickValue={isSm ? 4 : 7}
                    title={
                      <Typography variant='subtitle1' textAlign='left'>
                        Temperature
                      </Typography>
                    }
                    chartMargin={{ top: 20, right: 30, bottom: 50, left: 40 }}
                    data={seriesTemp.data}
                    max={seriesTemp.max}
                    min={seriesTemp.min}
                    colors={seriesTemp.colors}
                    formatType={seriesTemp.formatType}
                  />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Card fullContent>
                {!seriesWind ? (
                  <Skeleton height={300} />
                ) : (
                  <BaseTimeChart
                    style={{
                      margin: 16,
                      height: 300,
                    }}
                    bottomTickValue={isSm ? 4 : 7}
                    chartMargin={{ top: 20, right: 30, bottom: 50, left: 40 }}
                    title={
                      <Typography variant='subtitle1' textAlign='left'>
                        Wind Speed (mph)
                      </Typography>
                    }
                    data={seriesWind.data}
                    max={seriesWind.max}
                    min={seriesWind.min}
                    colors={seriesWind.colors}
                    enablePoints={false}
                    formatType={seriesWind.formatType}
                  />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Card fullContent>
                {!seriesPrecip ? (
                  <Skeleton height={300} />
                ) : (
                  <BaseTimeChart
                    style={{
                      margin: 16,
                      height: 300,
                    }}
                    chartMargin={{ top: 20, right: 30, bottom: 50, left: 40 }}
                    bottomTickValue={isSm ? 4 : 7}
                    title={
                      <Typography variant='subtitle1' textAlign='left'>
                        Precipitation (in)
                      </Typography>
                    }
                    data={seriesPrecip.data}
                    max={seriesPrecip.max}
                    min={seriesPrecip.min}
                    colors={seriesPrecip.colors}
                    enablePoints={false}
                    formatType={seriesPrecip.formatType}
                  />
                )}
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={12} md={3} textAlign='start' display='flex' alignItems='center'>
                <Typography variant='h5' color='textPrimary'>
                  Condition Snapshots
                </Typography>
                {auth.userData && (
                  <Grid ml={1}>
                    <Link onClick={() => handleExportOpen('condition_snapshots')}>
                      <Typography>Export Data</Typography>
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {wxData?.latitude !== undefined && wxData.longitude !== undefined && (
              <Grid item xs={12}>
                <Card fullContent>
                  <ConditionsTimeline
                    stationId={weatherStationId}
                    latitude={wxData?.latitude}
                    longitude={wxData?.longitude}
                    bottomTickValues={4}
                    chartMargin={{ top: 20, right: 30, bottom: 50, left: 40 }}
                    style={{ margin: 16, height: 300 }}
                  />
                </Card>
              </Grid>
            )}

            {hasFeature('EVAPOTRANSPIRATION') && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} sm={12} md={3} textAlign='start' display='flex' alignItems='center'>
                  <Typography variant='h5' color='textPrimary'>
                    Evapotranspiration
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={9} display='flex' justifyContent='flex-end'>
                  <Link
                    inactive={historicalETRange !== HisotricalEtRange['3Days']}
                    onClick={() => {
                      setHistoricalETRange(HisotricalEtRange['3Days']);
                    }}>
                    <Typography>3 Days</Typography>
                  </Link>

                  <Divider style={{ height: 16, width: 1, borderColor: theme.palette.divider, margin: 16 }} />

                  <Link
                    inactive={historicalETRange !== HisotricalEtRange['7Days']}
                    onClick={() => {
                      setHistoricalETRange(HisotricalEtRange['7Days']);
                    }}>
                    <Typography>7 Days</Typography>
                  </Link>

                  <Divider style={{ height: 16, width: 1, borderColor: theme.palette.divider, margin: 16 }} />

                  <Link
                    inactive={historicalETRange !== HisotricalEtRange.Custom}
                    onClick={() => {
                      setHistoricalETRange(HisotricalEtRange.Custom);
                    }}>
                    <Typography>Custom</Typography>
                  </Link>
                </Grid>

                {historicalETRange === HisotricalEtRange.Custom && (
                  <Grid item container xs={12} display='flex' justifyContent='flex-end' marginTop={2} marginBottom={2}>
                    <DateRangePicker
                      label='Start Date'
                      inputFormat='MM/DD/yyyy'
                      value={[new Date(etDateRange.start), new Date(etDateRange.end)]}
                      onChange={etDateRange => handleEtDateRangeChange(etDateRange)}
                      renderInput={(startProps, endProps) => (
                        <>
                          <TextField {...startProps} sx={{ maxWidth: 150 }} />
                          <Box height='100%' marginLeft={1} marginRight={1}>
                            <Typography height='100%' variant='h3'>
                              -
                            </Typography>
                          </Box>
                          <TextField {...endProps} sx={{ maxWidth: 150 }} />
                        </>
                      )}
                    />
                  </Grid>
                )}

                {historicalETRange === HisotricalEtRange.Custom && shouldShowCustomEtRangeError() && (
                  <Grid item container xs={12} display='flex' justifyContent='flex-end'>
                    <Typography color={theme.palette.error.main}>Custom Range must be 14 days or less.</Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <EvapotranspirationTimeline
                    stationId={weatherStationId}
                    days={historicalETRange === HisotricalEtRange['7Days'] ? 7 : 3}
                    range={etDateRange}
                    chartMargin={{ top: 20, right: 30, bottom: 50, left: 40 }}
                    style={{ margin: 16, height: 300 }}
                    showCustomTimeRange={historicalETRange === HisotricalEtRange.Custom}
                    rangeError={shouldShowCustomEtRangeError()}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ExportDataModal
        weatherStationId={weatherStationId}
        weatherStationName={wxData?.locationName}
        type={exportType}
        open={openExportModal}
        handleClose={() => {
          setOpenExportModal(false);
        }}
      />

      {!auth.userData && (
        <Grid padding={4}>
          <img
            src={PoweredByPW}
            alt='Powered by Perry Weather'
            style={{ height: 40, cursor: 'pointer' }}
            onClick={() => {
              window.open('https://perryweather.com');
            }}></img>
        </Grid>
      )}
    </Page>
  );
}

export default Historical;
