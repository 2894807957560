import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { getToken } from '../../Services/Auth/authSlice';
import { useAppSelector } from '../../app/hooks';
import { useUserQuery } from '../../Services/API';

export const MixpanelContainer = () => {
  const { REACT_APP_MIXPANEL_KEY } = process.env;

  useEffect(() => {
    if (REACT_APP_MIXPANEL_KEY) {
      mixpanel.init(REACT_APP_MIXPANEL_KEY, {
        debug: false,
        track_pageview: true,
        // @ts-ignore - This is a valid option
        autocapture: true,
      });
    }
  }, []);

  // Once a session token is available, identify the user in Mixpanel
  const token = useAppSelector(getToken);
  const { data, isSuccess } = useUserQuery(undefined, { skip: !token });

  useEffect(() => {
    if (REACT_APP_MIXPANEL_KEY && isSuccess && data) {
      mixpanel.identify(data.id);
      mixpanel.people.set({
        $email: data.email,
        $phone: data.phoneNumber,
        username: data.userName,
        customer_id: data.customerId,
        customer_name: data.customerName,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        role: data.roleName,
      });
    }
  }, [isSuccess, data]);

  return null;
};
