import { Box, Typography, useTheme } from '@mui/material';
import { Skeleton } from '@mui/material';
import { Card, Tooltip } from '@perry-weather/component-library';
import { useObservationsForLocationQuery } from '../../Services/API';
import { useAppSelector } from '../../app/hooks';
import { getSelectedLocation } from '../../features/dash/dashSlice';
import { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CurrentConditionsContainer } from '../CurrentConditions/CurrentConditionsContainer';
import Link from '../Base/Link';
import { formatTimeStringFromMins, getTimeDifferenceInMins, ConvertToUtc } from '../../features/Time/TimeHelpers';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ErrorBoundary } from 'react-error-boundary';

const boldedPrecipTextDesc = 'Radar-Correction Precipitation';
const precipTextDesc = ' is enabled on this weather station. Rainfall totals will update hourly.';

function WindDirToDegrees(direction?: string) {
  if (direction === 'N') {
    return 0;
  } else if (direction === 'NNW') {
    return 337.5;
  } else if (direction === 'NW') {
    return 315;
  } else if (direction === 'WNW') {
    return 292.5;
  } else if (direction === 'W') {
    return 270;
  } else if (direction === 'WSW') {
    return 247.5;
  } else if (direction === 'SW') {
    return 225;
  } else if (direction === 'SSW') {
    return 202.5;
  } else if (direction === 'S') {
    return 180;
  } else if (direction === 'SSE') {
    return 157.5;
  } else if (direction === 'SE') {
    return 135;
  } else if (direction === 'ESE') {
    return 112.5;
  } else if (direction === 'E') {
    return 90;
  } else if (direction === 'ENE') {
    return 67.5;
  } else if (direction === 'NE') {
    return 45;
  } else if (direction === 'NNE') {
    return 22.5;
  } else {
    return 0;
  }
}

export function CurrentConditionsCard() {
  const selectedLocation = useAppSelector(getSelectedLocation);

  const { data, isFetching, isError } = useObservationsForLocationQuery(
    selectedLocation ? selectedLocation.id || '' : '',
    {
      skip: selectedLocation === undefined,
    }
  );
  const [isSevere, setIsSevere] = useState(false);
  var weatherStation = data?.weatherStation;

  let skeltelAnimation: boolean = false;

  const theme = useTheme();

  return (
    <Card
      header='Current Conditions'
      scary={isSevere}
      action={
        selectedLocation && !isError && !isFetching && weatherStation && weatherStation.type !== 0 ? (
          <Link href={`/Historical/${weatherStation.id}`}>
            <Typography>Historical</Typography>
            <ArrowForwardIcon sx={{ width: 20, height: 16 }} />
          </Link>
        ) : (
          <></>
        )
      }>
      <ErrorBoundary fallback={<Typography>There was an error while trying to load current conditions</Typography>}>
      {
      isFetching ? (
        <Box display='flex' justifyContent='space-evenly'>
          <Skeleton animation={skeltelAnimation} variant='rectangular' width='100%' height={110} />
          <Skeleton animation={skeltelAnimation} variant='rectangular' width='100%' height={110} />
          <Skeleton animation={skeltelAnimation} variant='rectangular' width='100%' height={110} />
          <Skeleton animation={skeltelAnimation} variant='rectangular' width='100%' height={110} />
        </Box>
      ) : data && !isError && !isFetching ? (
        <>
          <CurrentConditionsContainer
            conditions={{
              temp: data.data?.ambientTemperature?.value,
              feelsLike: data.data?.feelLike?.value,
              precip: data.data?.precipitation?.value,
              wbgt: data.data?.wgbt?.value,
              windSpeed: data.data?.windSpeed?.value,
              windDirection: WindDirToDegrees(data.data?.windDirection),
              windDirectionText: data.data?.windDirection,
              calibratedConditions: {
                precip: data.calibratedData?.rainToday,
                precipUpdatedTime: data.calibratedData?.rainUpdatedTime,
              },
            }}
            isApprox={!data.weatherStation}
            setIsSevere={setIsSevere}
          />
          {data.data && data.data.observationTime && (
            <Box display='flex' justifyContent='flex-end'>
              <Box display='flex' flexDirection='column' justifyContent='flex-end'>
                <Box display='flex' justifyContent='flex-end'>
                  <Typography variant='caption' overflow='visible' noWrap>
                    {'Conditions updated ' +
                      formatTimeStringFromMins(
                        Math.round(getTimeDifferenceInMins(ConvertToUtc(data.data.observationTime)))
                      )}
                  </Typography>
                </Box>
                {data.calibratedData?.rainUpdatedTime !== undefined ? (
                  <Box display='flex' justifyContent='space-around'>
                    <Typography variant='caption' overflow='visible' noWrap>
                      {'Precipitation updated ' +
                        formatTimeStringFromMins(
                          Math.round(getTimeDifferenceInMins(ConvertToUtc(data.calibratedData?.rainUpdatedTime)))
                        )}
                    </Typography>
                    <Box display='flex' justifyContent='center' flexDirection='column'>
                      <Tooltip
                        title={
                          <Box>
                            <Typography component='span'>{boldedPrecipTextDesc}</Typography>
                            <Typography component='span'>{precipTextDesc}</Typography>
                          </Box>
                        }
                        arrow
                        placement='top'
                        enterDelay={300}
                        leaveDelay={200}>
                        <HelpOutlineIcon color='info' sx={{ fontSize: 14 }} />
                      </Tooltip>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
            <Typography variant='h4'>Unable to load current conditions</Typography>
            <Typography>No data is available for this location.</Typography>
        </>
      )}
        </ErrorBoundary>
    </Card>
  );
}
export default CurrentConditionsCard;
